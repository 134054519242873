import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "src/modules/common/components/DataTable";
import userDefault from "src/modules/common/components/images/user_default.png";
import ReactSelect from "react-select";
import TabButton from "src/modules/common/components/TabButton";
import { evaluacionActions } from "../handlers/redux";
import SearchInput from "src/modules/remuneracion/components/SearchInput";
import { DESCRIPCIONES_NINEBOX } from "../helpers/constants";

const datatableConfig = {
  headers: [
    {
      title: "Lista de colaboradores",
      contentClass: "font-bold",
      // onOrder: setOrderingPermisos("empleado"),
      // accessor: "nombre",
      contentRenderer: (item) => (
        <div className="flex flex-row gap-2 items-center p-2 cursor-pointer">
          <img
            className="object-cover h-14 w-14 rounded-md"
            src={userDefault}
            alt="Imagen de perfil"
          />
          <div className="flex flex-col gap-2">
            <h1 className="font-bold">
              {item.nombre} {item.apellido}
            </h1>
            <span>{item.cargo ? item.cargo.nombre : "Sin cargo"}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Puntaje",
      // accessor: "fecha_inicio",
      // onOrder: setOrderingPermisos("tipo_permiso"),
      contentRenderer: (item) => <h1>{item.data.promedio}</h1>,
    },
    {
      title: "Potencial",
      // accessor: "fecha_inicio",
      // onOrder: setOrderingPermisos("tipo_permiso"),
      contentRenderer: (item) => <h1>{item.data.puntaje_potencial}</h1>,
    },
    {
      title: "Desempeño",
      // accessor: "fecha_inicio",
      // onOrder: setOrderingPermisos("tipo_permiso"),
      contentRenderer: (item) => <h1>{item.data.puntaje_desempeno}</h1>,
    },
  ],
  rowActions: [],
};

const ColaboradoresNineboxTable = ({
  idEvaluacion,
  nineboxData,
  colaboradores,
  colaboradoresNineboxLoading,
}) => {
  const dispatch = useDispatch();

  const colaboradores_ninebox_checked = useSelector(
    (state) => state.evaluacion.colaboradores_ninebox_checked
  );
  const colaboradores_ninebox_filter = useSelector(
    (state) => state.evaluacion.colaboradores_ninebox_filter
  );
  const { nombre = "" } = colaboradores_ninebox_filter;

  const [tabSelected, setTabSelected] = useState(0);

  const onSearch = (e) => {
    const value = e.target.value;
    dispatch(
      evaluacionActions.setColaboradoresNineboxFilter({
        ...colaboradores_ninebox_filter,
        evaluacion_id: idEvaluacion,
        rango_index: tabSelected,
        nombre: value,
      })
    );
  };

  const actionOptions = [
    {
      value: "exportar",
      label: "Exportar",
    },
  ];

  const onAction = (v) => {
    if (v.value === "exportar") {
      console.log("exportar action", colaboradores_ninebox_checked);
      return;
    }
  };

  const onSelectTab = (index) => {
    setTabSelected(index);
    dispatch(
      evaluacionActions.setColaboradoresNineboxFilter({
        ...colaboradores_ninebox_filter,
        evaluacion_id: idEvaluacion,
        rango_index: index,
      })
    );
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(colaboradores));
    if (colaboradores_ninebox_checked.length > 0) {
      checkedData = [];
    }
    dispatch(evaluacionActions.setColaboradoresNineboxChecked(checkedData));
  };

  const onCheckItem = (item) => (event) => {
    dispatch(
      evaluacionActions.checkColaboradorNinebox({
        colaborador: item,
        checked: event.target.checked,
      })
    );
  };

  return (
    <>
      {nineboxData.length > 0 && (
        <>
          <div className="flex flex-row flex-wrap gap-2 items-center px-6">
            {nineboxData.map((item, index) => (
              <TabButton
                title={item.nombre}
                isActive={index === tabSelected}
                action={() => onSelectTab(index)}
              />
            ))}
          </div>
          {/* Título del rango del ninebox seleccionado */}
          {nineboxData[tabSelected] && (
            <div className="px-6 my-4">
              <h1 className="text-xl font-bold">
                {nineboxData[tabSelected].nombre}
              </h1>
            </div>
          )}
          <div className="w-full flex items-center gap-4 justify-between px-6">
            {/* Cambiar por el de remuneraciones, este se ejecuta todo el tiempo */}
            {/* @ts-ignore */}
            <SearchInput
              placeholder="Buscar por nombre"
              value={nombre}
              onChange={onSearch}
            />
            {/* @ts-ignore */}
            <ReactSelect
              className="w-auto h-10 inline-grid text-sm"
              value={{
                value: "",
                label: `(${colaboradores_ninebox_checked.length}) seleccionados`,
              }}
              options={actionOptions}
              onChange={onAction}
            />
          </div>
          <div className="w-full max-h-[600px] custom-scrollbar">
            <DataTable
              containerClass={"my-8"}
              data={colaboradores}
              loading={colaboradoresNineboxLoading}
              config={datatableConfig}
              //
              totalRecords={colaboradores.length}
              //
              checkable={true}
              onCheckAll={onCheckAll}
              checkedItems={colaboradores_ninebox_checked}
              onItemCheck={onCheckItem}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ColaboradoresNineboxTable;
