import React from "react";
import PropTypes from "prop-types";
import userDefault from "src/modules/common/components/images/user_default.png";
import { InfoCircleFill } from "react-bootstrap-icons";
import { DESCRIPCIONES_NINEBOX } from "../helpers/constants";

const getBoxColor = (index) => {
  if (index === 1 || index === 2 || index === 5) {
    return { bg: "rgb(232, 247, 229)", border: "rgb(30, 176, 0)" };
  } else if (index === 0 || index === 4 || index === 8) {
    return { bg: "rgb(255, 247, 230)", border: "rgb(240, 201, 120)" };
  } else if (index === 3 || index === 6 || index === 7) {
    return { bg: "rgb(252, 235, 235)", border: "rgb(246, 146, 105)" };
  }
  return { bg: "transparent", border: "transparent" };
};

/**
 * Componente que renderiza un NineBox con las cajas y ejes correspondientes.
 * Recibe como parámetro un array de objetos con los datos de cada caja, y
 * opcionalmente, un booleano para mostrar o no las descripciones de cada box,
 * y una clase adicional para personalizar el estilo.
 *
 * @param Array nineboxData - Array de objetos con los datos de cada caja.
 * @param Boolean [mostrarDescripcionesBox=true] - Booleano para mostrar o no todas las descripciones de cada box.
 * @param String [extraClassName=""] - Clase adicional para personalizar el estilo.
 * @returns ReactElement - Componente NineBox.
 */
const NineBox = ({ nineboxData, mostrarDescripcionesBox = true, extraClassName = "" }) => {
  // Reorganizar nineboxData en el orden deseado para mostrar los box correctamente
  const reorderedData =
    nineboxData.length === 9
      ? [
        nineboxData[5],
        nineboxData[2],
        nineboxData[0],
        nineboxData[7],
        nineboxData[4],
        nineboxData[1],
        nineboxData[8],
        nineboxData[6],
        nineboxData[3],
      ]
      : [];

  return (
    <>
      <div className="flex items-center space-x-4 pl-8 border-b py-4">
        <div className="flex items-center space-x-2">
          <span
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: "#ADD8E6" }} // Color azul claro
          ></span>
          <span className="text-sm font-bold">Vertical: Desempeño</span>
        </div>
        <div className="flex items-center space-x-2">
          <span
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: "#90EE90" }} // Color verde claro
          ></span>
          <span className="text-sm font-bold">Horizontal: Potencial</span>
        </div>
      </div>
      <div className="relative">
        {/* Ejes y flechas */}
        <div className="absolute bottom-4 left-0 w-full h-full pointer-events-none">
          {/* Flecha vertical */}
          <div className="absolute top-0 h-full flex flex-col items-center justify-between">
            <h1 className="text-xs">Alto</h1>
            <h1 className="text-xs">Medio</h1>
            <h1 className="text-xs">Bajo</h1>
          </div>
          <div className="absolute left-10 top-0 h-full flex flex-col items-center">
            <div className="flex-grow border-l-4 border-[#ADD8E6]"></div>
          </div>
        </div>

        {/* Caja del NineBox */}
        <div className={`grid grid-cols-3 gap-4 pl-14 ${extraClassName}`}>
          {reorderedData.map((box, index) => (
            <div
              key={box.id}
              className="flex flex-col justify-center items-center rounded-lg p-4 h-32 border-l-4"
              style={{
                backgroundColor: getBoxColor(index).bg,
                borderColor: getBoxColor(index).border,
              }}
            >
              <div className="flex flex-row justify-between items-center gap-2">
                <h3 className="text-lg font-bold">{box.nombre}</h3>
                {/* En reporte individual, solo se muestra el tooltip con la descripción en el box en el que se encuentra el colaborador */}
                {(mostrarDescripcionesBox || !!box.colaborador) && (
                  <div className="relative group">
                    <InfoCircleFill size={24} />
                    <div className="absolute bottom-full w-[600px] mb-2 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-gray-800 text-white text-xs rounded-md px-2 py-1 shadow-lg">
                      {DESCRIPCIONES_NINEBOX[index]}
                    </div>
                  </div>
                )}
              </div>
              <p className="text-sm">
                Puntaje: {box.puntaje_desde} - {box.puntaje_hasta}%
              </p>
              {box.cantidad_colaboradores >= 0 && (
                <p className="rounded-md px-2 py-1 bg-zinc-700 text-white text-sm mt-2">
                  {box.cantidad_colaboradores}{" "}
                  {box.cantidad_colaboradores === 1
                    ? "colaborador"
                    : "colaboradores"}
                </p>
              )}
              {box.colaborador && (
                <div className="flex flex-row justify-center items-center gap-4 mt-2">
                  <img
                    className="w-10 h-10 rounded-md"
                    src={box.colaborador.foto_empleado || userDefault}
                    title={box.colaborador.nombre_completo}
                    alt="Foto de perfil"
                  />
                  <p className="rounded-md p-2 bg-zinc-700 text-white text-sm">
                    Se encuentra aquí
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Flecha horizontal */}
        <div>
          <div className="border-2 ml-10 border-[#90EE90]"></div>
          <div className="ml-10 flex justify-between">
            <h1 className="text-xs">Bajo</h1>
            <h1 className="text-xs">Medio</h1>
            <h1 className="text-xs">Alto</h1>
          </div>
        </div>
      </div>
    </>
  );
};

NineBox.propTypes = {
  nineboxData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nombre: PropTypes.string.isRequired,
      puntaje_desde: PropTypes.number.isRequired,
      puntaje_hasta: PropTypes.number.isRequired,
      cantidad_colaboradores: PropTypes.number,
      colaborador: PropTypes.shape({
        id: PropTypes.number,
        nombre_completo: PropTypes.string,
        foto_empleado: PropTypes.string,
        promedio_evaluacion: PropTypes.number,
      }),
    })
  ).isRequired,
  extraClassName: PropTypes.string,
};

export default NineBox;
